import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { BookingForm } from "../context/context"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../containers/hero-section-dynamic"
import Instagram from "../containers/instagram"
import ContentBlock from "../containers/content-block"
import Carousel from "../containers/carousel"
import ContentArea from "../dynamic-zone/content-area"
import ContactUs from "../containers/contact-us"
import saltLogo from "../images/salt.png"
import SaltHero from "../images/salt-hero.png"
import Eat from "../images/eat-eat-eat.png"

const SaltSushi = ({ data, location }) => {
  const { setBookingForm } = useContext(BookingForm)
  useEffect(() => {
    setBookingForm({
      open: false,
      type: "Salt Restaurant",
      id: 5,
      eventId: 5,
      name: "Salt Restaurant",
      resTime: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
    })
  }, [])

  console.log(data.strapiRestaurant.MobileHeroSection.image)
  return (
    <Layout
      location={location}
      // title={siteTitle}
      logo={saltLogo}
      color="bg-saltRestaurant-50"
      ctaColor="bg-saltRestaurant-50"
      textCtaColor="text-white"
      // formData={formData}
    >
      <Seo title="Salt Sushi & Steak" />
      {/* <HeroSection image={SaltHero} /> */}
      <HeroSection
        image={
          data.strapiRestaurant.HeroSectionRestaurants.backgroundImageRestaurant
        }
        smallImage={data.strapiRestaurant.MobileHeroSection.image}
        sectionType={"restaurant"}
      />
      {/* <ContentBlock title="LACE BEACH FOOD" color="bg-saltRestaurant-50" />
      <Carousel carouselTopImage={Eat} color="bg-saltRestaurant-50" /> */}
      <ContentArea
        carouselTopImage={Eat}
        color="bg-saltRestaurant-50"
        contentTypes={data.strapiRestaurant.pageContent}
        title={data.strapiRestaurant.restaurantName}
        // instaNodes={data.allInstaNode.edges}
      />
      {/* <ContactUs color="bg-saltRestaurant-50" />
      <ContentBlock title="SALT SUSHI & STEAK" color="bg-saltRestaurant-50" />
      <Carousel carouselTopImage={Eat} color="bg-saltRestaurant-50" /> */}
      <ContactUs
        color="bg-saltRestaurant-50"
        contactHeaderColor="text-white"
        contactSubHeaderColor="text-white"
        contactContentColor="text-white"
        contactCtaColor="text-white"
        contactCtaBorderColor="border-white"
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11375.242686696509!2d14.913898!3d44.539509!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2477bbca346ac78a!2sSalt%20Sushi%20%26%20Steak!5e0!3m2!1shr!2sba!4v1669142539023!5m2!1shr!2sba"
        }
      />
      {/* <Instagram title={"salt_sushi_and_steak"} ellipse bgColor="#80D361" /> */}
    </Layout>
  )
}

export default SaltSushi

export const pageQuery = graphql`
  query MyQueryFifth {
    strapiRestaurant(strapi_id: { eq: 3 }) {
      id
      restaurantName
      HeroSectionRestaurants {
        altTag
        backgroundImageRestaurant {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      MobileHeroSection {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CAROUSEL {
          id
          cloudText
          strapi_component
          RestaurantCarouselImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CONTENT_BLOCK {
          id
          strapi_component
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
